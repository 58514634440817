import axios from "axios"

let API_NAME = process.env.VUE_APP_API_NAME;

export default {
	async getStones(accessToken) {
		let res = await axios.get(API_NAME + "/stones", {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
  async getOnHoldStones(accessToken) {
		let res = await axios.get(API_NAME + "/stones?on_hold_only=1", {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
  async getAvailableStones(accessToken) {
		let res = await axios.get(API_NAME + "/stones?available_only=1", {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
  async getStoneTypes(accessToken) {
	let res = await axios.get(API_NAME + "/stone_types", {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},
async getStoneType(accessToken, id) {
	let res = await axios.get(API_NAME + "/stone_types/" + id, {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},
async archiveStoneType(accessToken, stoneTypeID) {
  let res = await axios.post(API_NAME + "/stone_types/" + stoneTypeID,  {
          'active' : 0,
          _method: 'patch'
      },
      {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  return res.data;
},
async getStoneMakes(accessToken) {
	let res = await axios.get(API_NAME + "/stone_makes?with_stones=1", {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},
async getStoneByBarcode(accessToken, barcode) {
	let res = await axios.get(API_NAME + "/stones/barcode/" + barcode, {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},
async getStone(accessToken, id) {
	let res = await axios.get(API_NAME + "/stones/" + id, {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},
async getJob(accessToken, id) {
	let res = await axios.get(API_NAME + "/jobs/" + id, {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},
async getBatch(accessToken, id) {
	let res = await axios.get(API_NAME + "/batches/" + id, {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},

async getColours(accessToken) {
	let res = await axios.get(API_NAME + "/colours", {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},
async getJobs(accessToken) {
	let res = await axios.get(API_NAME + "/jobs", {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},
async getSuppliers(accessToken) {
	let res = await axios.get(API_NAME + "/suppliers", {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},
async getDeliveries(accessToken, app_view) {
  let query_string = ''
  if(app_view == 1)
  {
    query_string = '?received=0'
  }
	let res = await axios.get(API_NAME + "/deliveries" + query_string, {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},
async getDelivery(accessToken, id) {
	let res = await axios.get(API_NAME + "/deliveries/" + id, {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		});
  return res.data;
},
async saveStone(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/stones",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateStone(accessToken, formdata, id) {
    let res = await axios.post(API_NAME + "/stones/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateJob(accessToken, formdata, id) {
    let res = await axios.post(API_NAME + "/jobs/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateBatchPhoto(accessToken, formdata, id) {
    let res = await axios.post(API_NAME + "/batches/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateDelivery(accessToken, formdata, id) {
    let res = await axios.post(API_NAME + "/deliveries/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async setStoneInUse(accessToken, id, formdata) {
    let res = await axios.post(API_NAME + "/stones/" + id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  
	async saveStoneType(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/stone_types",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateStoneType(accessToken, formdata, stone_type_id) {
    let res = await axios.post(API_NAME + "/stone_types/" + stone_type_id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveStoneMake(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/stone_makes",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveColour(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/colours",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveSupplier(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/suppliers",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveJob(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/jobs",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveDelivery(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/deliveries",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveBatch(accessToken, formdata) {
    let res = await axios.post(API_NAME + "/batches",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },

  // REPORTS DATA
  async getBatches(accessToken, order_by) {
    let res = await axios.get(API_NAME + "/batches?order_by=" + order_by, {
        headers: {
        Authorization: `Bearer ${accessToken}`
        }
      });
    return res.data;
  },
  
}